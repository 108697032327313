<div
  id="{{item.artifact_id}}"
  class="gallery-item"
  queryParamsHandling="merge"
  [ngClass]="{'gallery-item--selected' : searchContainer.selections.selected[item.artifact_id]}"
  [queryParams]="item.$$refData?.queryParams"
  [routerLink]="getRouterLink(item)"
>
  <div
    class="gallery-item__checkbox-container"
    [ngClass]="{'gallery-item__checkbox-container--selected' : searchContainer.selections.selected[item.artifact_id]}"
  >
    <span
      class="checkbox-tooltip-wrapper"
      [class.cursor-not-allowed]="isCheckboxDisabled(item) && !item.$$used"
      [matTooltip]="isCheckboxDisabled(item) && !item.$$used ? ('TRANS__SEARCH_SELECTIONS__TOO_MANY_SELECTED' | translate) : ''"
      (click)="$event.stopPropagation()">
      <mat-checkbox
        (click)="select(item, $event)"
        [checked]="isCheckboxSelected(item)"
        [disabled]="isCheckboxDisabled(item)"
      />
    </span>
  </div>

  @if(item.hasOwnProperty('$$imageUrl')) {
    <img
      [alt]=""
      class="gallery-item__image"
      [src]="item.$$imageUrl"
    />
  }
  @else {
    <div class="gallery-item__placeholder-container">
      <mat-icon class="gallery-item__placeholder">
        {{ getMaterialSymbol(item.object_type) }}
      </mat-icon>
    </div>
  }
</div>
