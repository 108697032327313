import {Injectable} from '@angular/core';
import {CmsApiService} from '../core/cms-api.service';
import {CommonsService} from '../core/commons.service';
import {AConst} from '../core/a-const.enum';
import {FocusServiceImplementation, SearchFocusService} from './search-focus.service';
import {
  RootSearchView,
  SearchViewPath
} from '../core/definitions/search-objects';
import {QueryFieldMenuService} from './query-field-menu.service';
import {ResultViewService} from './result-view.service';
import {MetaField} from '../core/definitions/meta-field';
import {GetArtifactParams} from '../core/definitions/get-artifact-params';
import {SuperObjectModel} from '../core/definitions/super-object-model';
import {SearchStateService} from './search-state.service';
import {SearchFilterService} from './search-filter.service';
import {SearchExecutorService} from './search-executor.service';
import {SearchResultSelectionsService} from './search-result-selections.service';
import {SearchFilters} from '../core/definitions/search-filters';
import {UserCacheService} from '../core/user-cache.service';
import {SearchTemplateGroupService} from './search-template-group.service';
import {SearchContainer, SearchContainerParams} from '../core/definitions/search-container';
import {Focus} from '../core/definitions/focus';
import {PrimusRouterService} from '../core/primus-router.service';
import {SearchViewSortOrderMenuService} from "./search-view-sort-order-menu.service";
import {SearchViewFilterService} from "./search-view-filter.service";

export interface SearchMenuPath {
  title: string;
  targetId: string;
  path: string;
}

@Injectable({
  providedIn: 'root'
})
export class SearchHandlerService {

  private fsi: FocusServiceImplementation;

  constructor(private primusRouter: PrimusRouterService,
              private cms: CmsApiService,
              private commons: CommonsService,
              private searchFocusService: SearchFocusService,
              private queryFieldMenuService: QueryFieldMenuService,
              private resultViewService: ResultViewService,
              private searchStateService: SearchStateService,
              private searchFilterService: SearchFilterService,
              private searchExecutorService: SearchExecutorService,
              private searchResultSelectionsService: SearchResultSelectionsService,
              private userCacheService: UserCacheService,
              private searchTemplateGroupService: SearchTemplateGroupService,
              private sortOrderMenuService: SearchViewSortOrderMenuService,
              private searchViewFilterService: SearchViewFilterService) {
  }

  /**
   * @param params Params for creating search container
   * @param.searchViewName Name of search view to retrieve from
   * server.
   * @param.filter Search filters, which will be added to search view
   * filters
   * @param.targetState The target state name that will be used when
   * setting state parameters.
   * @param.stateParams Initial state parameters that will be used
   * to set some search container attributes. Relevant for
   * page reload.
   * @param.targetObject An object that will be associated with the
   * search container. The object can later be accessed directly
   * from the search container object.
   */
  async createSearchContainer(params: SearchContainerParams): Promise<SearchContainer> {
    const filters = params.filters || {};
    const view = await this.getSearchView(params, filters);
    const searchContainer: SearchContainer = new SearchContainer();
    if (view) {
      searchContainer.restrictions = params.restrictions;
      searchContainer.rows = params.rows;
      searchContainer.searchPostFn = params.searchPostFn;
      searchContainer.searchResultViews = view.search_result_views;
      searchContainer.searchView = view;
      searchContainer.state.targetState = params.targetState;
      searchContainer.targetObject = params.targetObject;
      searchContainer.qOp = params.qOp;
      this.searchTemplateGroupService.setTemplateGroup(searchContainer, params.templateGroupId);
      await this.searchTemplateGroupService.setListFieldTemplateGroup(searchContainer, params.listFieldTemplateId);

      searchContainer.used = params.used;
      searchContainer.keepSelected = params.keepSelected;
      this.searchResultSelectionsService.setSelections(searchContainer, params.selectedItems || []);
      this.resultViewService.calculateAndSetsDefaultRowsColumns(searchContainer);
      if (params.getFocus) {
        this.fsi = this.searchFocusService.createFSI(searchContainer);
        await this.fsi.loadSavedFocuses();
        const curFocus = this.setSearchContainerFromFocus(this.fsi, params);
        if (!curFocus) {
          this.resultViewService.setDefaultSearchResultView(searchContainer);
        }
        this.setDefaultSearchContainerProps(searchContainer, params);
        return this.finishSearchContainerCreation(searchContainer, params);
      } else {
        this.resultViewService.setDefaultSearchResultView(searchContainer);
        this.setDefaultSearchContainerProps(searchContainer, params);
        return this.finishSearchContainerCreation(searchContainer, params);
      }
    } else {
      console.warn('No view: ' + params.searchViewName);
    }
  }

  getPathView(path: string, searchContainer: SearchContainer): SearchViewPath {
    let pathView: SearchViewPath;
    const searchView = searchContainer.searchView;
    if (searchView) {
      pathView = this.findPathView(path, searchView);
      if (!pathView) {
        console.warn('Could not find path \'' + path + '\' ' +
          'in menu view');
      }
    } else {
      console.warn('Search view not defined in search container');
    }
    return pathView;
  }

  setOrder(searchContainer: SearchContainer, fieldName: string, fieldInfo?: MetaField) {
    const curOrder = searchContainer.getOrder();
    let orderFieldName = fieldName;
    if (fieldInfo && fieldInfo.input_type === AConst.MAP_ID) {
      orderFieldName = fieldName + '_value';
    }
    if (fieldName.indexOf(' asc') !== -1 || fieldName.indexOf(' desc') !== -1) {
      searchContainer.setOrder(fieldName, false);
    } else if (curOrder.indexOf(orderFieldName) === -1 || curOrder.indexOf(' desc') > 1) {
      searchContainer.setOrder((fieldInfo && fieldInfo.path && !orderFieldName.includes('.')) ?
        fieldInfo.path + '.' + orderFieldName : orderFieldName, false);
    } else {
      searchContainer.setOrder(  `${searchContainer.getOrder()} desc`, false);
    }
    if (searchContainer.getOrder().includes('{random}')) {
      searchContainer.orderRandomSeed = Math.round(Math.random() * 10000).toString();
    }
    this.searchExecutorService.resetSearchPosition(searchContainer);
    this.searchExecutorService.runSearch(searchContainer).then(() => {

    });
  }

  async goPathView(path: string, searchContainer: SearchContainer, keepFilterGroups: boolean = false) {
    const paramsSetFromFocus = this.checkSetParamsFromFocus(path, searchContainer);
    searchContainer.path = path;
    this.searchExecutorService.resetSearchPosition(searchContainer);
    searchContainer.searchResult = null;
    const pathView = await this.setCurrentPathView(searchContainer);
    if (!pathView.search_view.override_target) {
      this.resultViewService.setCurrentResultViewFromPathView(searchContainer, pathView);
      this.searchFilterService.resetFilter(searchContainer);
      const sortOrderMenus = await this.sortOrderMenuService.getSortOrderMenus(
        searchContainer.currentPathView.search_view.sort_order_menus);
      if (!paramsSetFromFocus && searchContainer.orderIsDefault && sortOrderMenus) {
        searchContainer.setOrder(sortOrderMenus[0].order, true);
      }
      searchContainer.selections.allSelected = false;
      setTimeout(() => {
        this.runSearchAfterPathViewChange(searchContainer, keepFilterGroups).then(() => {
          this.searchFilterService.setDefaultCheckedFilters(searchContainer);
        });
      }, 1000);
    }
  }


  async goSearchMenu(menu: any, searchContainer: SearchContainer) {
    searchContainer.targetId = menu.targetId;
    searchContainer.objectCount = menu.objectCount;
    await this.goPathView(menu.path, searchContainer);
  }

  getSearchMenuPath(searchContainer: SearchContainer): SearchMenuPath[] {
    let pathView = searchContainer.currentPathView;
    const pathNames = this.getPath(searchContainer).split('/');
    const res = [];
    let path = '', pathSplit = '';
    pathNames.forEach((pathName, index) => {
      path += pathSplit + pathName;
      pathView = this.getPathView(path, searchContainer);
      if (pathView) {
        const targetId = pathName === 'folder' ? searchContainer.targetId : 'none';
        const menuPath = {
          title: pathView.search_view.title,
          targetId: targetId,
          path: null
        } as SearchMenuPath;
        if (index + 1 < pathNames.length) {
          menuPath.path = path;
        }
        res.push(menuPath);
        pathSplit = '/';
      }
    });
    return res;
  }

  setSearchItemIndex(searchContainer: SearchContainer, index: number) {
    searchContainer.searchItemIndex = index;
  }

  private async runSearchAfterPathViewChange(searchContainer: SearchContainer, keepFilterGroups: boolean = false): Promise<void> {
    if (!keepFilterGroups || !searchContainer.filtersFacets.filterGroups) {
      this.searchViewFilterService.setCheckFilterGroupsFromSearchView(searchContainer);
    }
    await this.searchExecutorService.runSearch(searchContainer);
    this.searchFilterService.checkMenusFromCheckedFilters(searchContainer);
    await this.searchFilterService.setCheckFilterGroups(searchContainer, false, true);
    if (searchContainer.focus.curFocusId) {
      this.searchFilterService.setTotalSelectedFilters(searchContainer);
      this.setCurrentFocusName(searchContainer);
    }
  }

  private checkSetParamsFromFocus(path: string, searchContainer: SearchContainer): boolean {
    let paramsSetFromFocus = false
    const curFocusId = searchContainer.focus.curFocusId;
    if (curFocusId) {
      const pathParams = this.fsi.getSetFocusPathParams(curFocusId, path);
      if (pathParams) {
        this.fsi.setFocusPathParamsOnSearchContainer(pathParams);
        paramsSetFromFocus = true;
      }
    }
    return paramsSetFromFocus;
  }

  private async finishSearchContainerCreation(searchContainer: SearchContainer,
                                              params: SearchContainerParams): Promise<SearchContainer> {
    this.setSearchResultViewDefaultRows(searchContainer);
    if (searchContainer.focus.curFocusId) {
      this.setCurrentFocusName(searchContainer);
    }
    const pathView = await this.setCurrentPathView(searchContainer);
    this.searchFilterService.setCheckedFiltersFromSearchContainer(searchContainer,
      !!pathView, params.defaultCheckedFilters);

    if (params.runSearch) {
      await this.searchExecutorService.runSearch(searchContainer);
      this.searchFilterService.setDefaultCheckedFilters(searchContainer);
      return searchContainer;
    } else {
      return searchContainer;
    }
  }

  private findPathView(pathName: string, searchView: RootSearchView): SearchViewPath {
    let res: SearchViewPath;
    if (searchView.paths) {
      for (const path of searchView.paths) {
        if (path.path_name === pathName) {
          res = this.commons.copy(path);
          break;
        }
      }
    } else {
      console.warn('No paths!');
    }
    return res;
  }

  private getFolderDate(searchContainer: SearchContainer) {
    searchContainer.targetObject['date'] = searchContainer.targetObject.created_at;
    return searchContainer;
  }

  private getPath(searchContainer: SearchContainer): string {
    if (!searchContainer.path) {
      searchContainer.path = 'home';
    }
    return searchContainer.path;
  }

  private async getSearchView(params: SearchContainerParams, filters: any): Promise<RootSearchView> {
    let view = params.searchViewData;
    if (!view) {
      const viewName = params.searchViewName;
      if (!viewName) {
        console.error('Missing view name');
        return view;
      }
      view = await this.userCacheService.getSearchView(viewName);
    }
    if (view) {
      this.setSearchViewFilters(view, filters);
    }
    return view;
  }

  private setSearchViewFilters(view: RootSearchView, filters: any) {
    if (!(filters && Object.keys(filters).length)) {
      return;
    }
    for (const viewPath of view.paths) {
      viewPath.search_view.$$origFilters = viewPath.search_view.$$origFilters || viewPath.search_view.filters ||
        new SearchFilters();
      viewPath.search_view.filters = {...viewPath.search_view.$$origFilters};
      for (const [filterName, filterValue] of Object.entries(filters)) {
        this.setViewPathFilterValues(viewPath, filterName, filterValue);
      }
    }
  }

  private setViewPathFilterValues(viewPath: SearchViewPath, filterName: string, filterValue: any) {
    let filterValues = Array.isArray(filterValue) ? filterValue : [filterValue];
    let viewFilterValues = viewPath.search_view.filters[filterName];
    if (viewFilterValues) {
      viewFilterValues = Array.isArray(viewFilterValues) ? viewFilterValues : [viewFilterValues];
      viewFilterValues = viewFilterValues.concat(filterValues);
    } else {
      viewFilterValues = filterValues;
    }
    viewPath.search_view.filters[filterName] = viewFilterValues;
  }

  private async loadTargetObject(searchContainer: SearchContainer): Promise<SuperObjectModel> {
    const targetId = searchContainer.targetId;
    let res = searchContainer.targetObject;
    if (targetId && targetId !== 'none') {
      const params = new GetArtifactParams();
      params.artifact_id = searchContainer.targetId;
      res = await this.cms.getArtifact(params);
    }
    return res;
  }

  private setCurrentFocusName(searchContainer: SearchContainer) {
    for (const focus of searchContainer.focus.focuses) {
      if (focus.focusId === searchContainer.focus.curFocusId) {
        searchContainer.focus.currentFocusName = focus.focus.name;
      }
    }
  }

  private async setCurrentPathView(searchContainer: SearchContainer): Promise<SearchViewPath> {
    const path = this.getPath(searchContainer);
    const pathView = this.getPathView(path, searchContainer);
    if (pathView) {
      searchContainer.currentPathView = pathView;
      if (searchContainer.extendedSearchParams) {
        searchContainer.extendedSearchParams.superobject_type_ids = pathView.search_view.superobject_types;
      }
      if (pathView.reset_query) {
        searchContainer.queryContainer.query = '';
      }
      await this.queryFieldMenuService.setSelectedQueryFieldMenu(searchContainer);
    }
    searchContainer.targetObject = await this.loadTargetObject(searchContainer);
    if (searchContainer.targetObject &&
      searchContainer.currentPathView.search_view.search_view_type === 'folder') {
      searchContainer.object = {
        artifact_id: searchContainer.targetObject[AConst.CREATED_BY_ID]
      };
      this.getFolderDate(searchContainer);
    }
    return pathView;
  }

  private setDefaultSearchContainerProps(searchContainer: SearchContainer, params: SearchContainerParams) {
    const toState = this.primusRouter.currentState();

    if (params.stateParams && toState &&
      toState.indexOf(params.targetState) === 0) {
      this.searchStateService.setSearchContainerPropsFromStateParams(searchContainer, params.stateParams);
      this.searchFilterService.setTotalSelectedFilters(searchContainer);
    }
  }

  private setSearchContainerFromFocus(fsi: FocusServiceImplementation, params: SearchContainerParams) {
    let curFocus: Focus;
    const curFocusId = params.stateParams['cur_focus_id'];
    if (curFocusId) {
      curFocus = fsi.setCurrentFocus(curFocusId);
    } else if (params.useFocus) {
      curFocus = fsi.getCurrentOrDefaultFocus();
      fsi.setCurrentFocus(curFocus);
    }
    if (curFocus) {
      fsi.setFocusPathParamsOnSearchContainer(curFocus.focus.pathParams[curFocus.focus.homePath]);
    }
    return curFocus;
  }

  private setSearchResultViewDefaultRows(searchContainer: SearchContainer) {
    for (const searchResultView of searchContainer.searchView.search_result_views.views) {
      const rowsAndColumns = this.resultViewService.getDefaultCalculatedRowsColumns(searchResultView.name);
      if (rowsAndColumns) {
        searchContainer.rows[searchResultView.name] = rowsAndColumns.rows;
      }
    }
  }

}
