<div
  id="{{item.artifact_id}}"
  class="thumbnail-item"
  [ngClass]="{'thumbnail-item--selected' : searchContainer.selections.selected[item.artifact_id]}"
  queryParamsHandling="merge"
  [queryParams]="item.$$refData?.queryParams"
  [routerLink]="getRouterLink(item)"
>
  <div
    class="thumbnail-item__checkbox-container"
    [ngClass]="{'thumbnail-item__checkbox-container--selected' : searchContainer.selections.selected[item.artifact_id]}"
  >
    <span class="checkbox-tooltip-wrapper"
          [class.cursor-not-allowed]="isCheckboxDisabled(item) && !item.$$used"
          [matTooltip]="isCheckboxDisabled(item) && !item.$$used ? ('TRANS__SEARCH_SELECTIONS__TOO_MANY_SELECTED' | translate) : ''"
          (click)="$event.stopPropagation()">
      <mat-checkbox
        (click)="select(item, $event)"
        [checked]="searchContainer.selections.selected[item.artifact_id]"
        [disabled]="isCheckboxDisabled(item)"
      />
    </span>
  </div>

  @if(item.hasOwnProperty('$$imageUrl')) {
    <img
      [alt]=""
      class="thumbnail-item__image"
      [src]="item.$$imageUrl"
    />
  }
  @else {
    <div class="thumbnail-item__placeholder-container">
      <mat-icon class="thumbnail-item__placeholder">
        {{ getMaterialSymbol(item.object_type) }}
      </mat-icon>
    </div>
  }

  <div class="thumbnail-item__content">

    <div class="thumbnail-item__text">
      <div class="thumbnail-item__title"><mat-icon class="thumbnail-item__icon">{{ getMaterialSymbol(item.object_type) }}</mat-icon> {{item.artifact_name}}</div>
      @for (label of item.overview_simple; track $index) {
        <span class="thumbnail-item__description">{{label.value}}</span>
      }
    </div>

  </div>
</div>
