import { Component, effect, input, signal, WritableSignal } from '@angular/core';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { ExtendedFieldQuery, ExtendedFieldQueryGroup } from 'src/app/core/definitions/extended-search-params';
import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { ExtendedSearchService } from '../../../../core/extended-search.service';
import { SearchContainer } from '../../../../core/definitions/search-container';
import {
  ExtendedSearchQueryBuilderValueDisplayComponent
} from '../../extended-search-query-builder-value-display/extended-search-query-builder-value-display.component';
import { SearchObject } from '../../../../core/definitions/search-object';

@Component({
  selector: 'app-extended-search-query-builder-field-value',
  standalone: true,
  imports: [
    MatFormField,
    MatIcon,
    MatInput,
    MatLabel,
    MatSuffix,
    TranslateModule,
    CdkConnectedOverlay,
    CdkOverlayOrigin,
    ExtendedSearchQueryBuilderValueDisplayComponent
  ],
  templateUrl: './extended-search-query-builder-field-value.component.html',
  styleUrl: './extended-search-query-builder-field-value.component.scss'
})
export class ExtendedSearchQueryBuilderFieldValueComponent {
  dropdownOpen: boolean = false;

  disabled = input<boolean>(false);
  parentGroup = input.required<ExtendedFieldQueryGroup>();
  rootGroup = input.required<ExtendedFieldQueryGroup>();
  searchContainer = input.required<SearchContainer>();
  selfField = input.required<ExtendedFieldQuery>();

  expandValueNodes: WritableSignal<boolean> = signal<boolean>(false);
  filterInputDebounce = null;
  filterQuery: WritableSignal<string> = signal<string>('');
  values: WritableSignal<SearchObject[]> = signal<SearchObject[]>([]);

  constructor(
    private readonly extendedSearchService: ExtendedSearchService
  ) {
    effect(() => {
      if (this.hasDropdown) {
        if (this.filterQuery() === '') {
          this.expandValueNodes.set(false);
        }
        else {
          this.expandValueNodes.set(true);
        }
      }
    }, {
      allowSignalWrites: true
    });

    effect(() => {
      if (this.hasDropdown) {
        this.getValueSelectorOptions();
      }
    }, {
      allowSignalWrites: true
    });

    effect(() => {
      if (this.filterQuery() !== '') {
        this.getValueSelectorOptions(false, this.filterQuery());
      }
      else {
        this.getValueSelectorOptions();
      }
    });

    effect(() => {
      if (this.selfField().valueDisplay !== null && this.selfField().valueDisplay !== undefined) {
        this.filterQuery.set(Array.isArray(this.selfField().valueDisplay) ? '' : this.selfField().valueDisplay as string);
      }
      else {
        this.filterQuery.set('');
      }
    }, {
      allowSignalWrites: true
    });
  }

  get arraySelectedValues(): string {
    if (Array.isArray(this.selfField().valueDisplay) && this.selfField().valueDisplay.length) {
      return (this.selfField().valueDisplay as string[]).join(', ');
    }
    return '';
  }

  get inputType(): string {
    if (this.selfField().input_type === 'date_iso' ||
        this.selfField().input_type === 'text_area') {
      return 'text';
    }

    if (this.selfField().input_type === 'number') {
      return 'number';
    }

    // Default is text as this is what is used for dropdown
    return 'text'
  }

  get isRange(): boolean {
    return this.selfField().operator_selected === 'range' || this.selfField().operator_selected === 'not range';
  }

  get hasDropdown(): boolean {
    return this.selfField().input_type === 'map_id' ||
           this.selfField().input_type === 'ref_array' ||
           this.selfField().input_type === 'search_selector_multiple' ||
           this.selfField().input_type === 'search_selector';
  }

  get hasValue(): boolean {
    return this.filterQuery() !== '' || !!this.selfField().valueDisplay;
  }

  arrayValueSelected(): void {
    // Necessary?
  }

  clearValueInput(event: MouseEvent): void {
    event.stopPropagation();

    this.filterQuery.set('');
    this.extendedSearchService.clearSelectedValue(this.selfField());
  }

  filterInput(event: Event) {
    if (this.filterInputDebounce !== null) {
      clearTimeout(this.filterInputDebounce);
    }

    this.filterInputDebounce = setTimeout(() => {
      // @ts-expect-error value DOES exist on target
      this.filterQuery.set(event.target.value);

      if (!this.hasDropdown) {
        // @ts-expect-error value DOES exist on target
        this.extendedSearchService.setSelectedFieldValueFromString(this.selfField(), event.target.value);
      }
    }, 500);
  }

  focusFirstNodeInDropdown(event) {
    event.preventDefault();

    if (this.dropdownOpen) {
      // @ts-ignore
      document.querySelectorAll('.value-tree__node:not(.value-tree__node--hidden) > .value-tree__node-button')[0].focus();
      // Select the children with class value-tree__node-button of every element WITH class value-tree__node and WITHOUT .value-tree__node--hidden
      // then focus the first of the resulting array
    }
    else {
      this.dropdownOpen = true;

      setTimeout(() => {
        // @ts-ignore
        document.querySelectorAll('.value-tree__node:not(.value-tree__node--hidden) > .value-tree__node-button')[0].focus();
        // Select the children with class value-tree__node-button of every element WITH class value-tree__node and WITHOUT .value-tree__node--hidden
        // then focus the first of the resulting array
      }, 100);
    }
  }

  getInternalDisplayValue(index: number) {
    if (!Array.isArray(this.selfField().value)) {
      return '';
    }

    return this.selfField().value[index];
  }

  setInternalValue(firstField?, secondField?) {
    if (!Array.isArray(this.selfField().value)) {
      this.extendedSearchService.setSelectedFieldValueFromArray(this.selfField(), [firstField ? firstField.target.value : null, secondField ? secondField.target.value : null]);
    }
    else {
      if (firstField) {
        this.extendedSearchService.setSelectedFieldValueFromArray(this.selfField(), [firstField.target.value, this.selfField().value[1]]);
      }

      if (secondField) {
        this.extendedSearchService.setSelectedFieldValueFromArray(this.selfField(), [this.selfField().value[0], secondField.target.value]);
      }
    }
  }

  valueSelected(name: string) {
    this.filterQuery.set(name);
    this.dropdownOpen = false;
  }

  private getValueSelectorOptions(clearValues: boolean = false, query: string = '') {
    if (clearValues) {
      this.values.set([]);
      return;
    }

    if (!this.selfField().reference_id) {
      return;
    }

    const path = this.selfField().path ?? this.selfField().field_name;

    if (!path) {
      return;
    }

    let superObjectTypeIds = [];
    const relationInfoFromGroup = this.extendedSearchService.getRelationInfoFromGroup(this.parentGroup(), this.rootGroup());

    if (relationInfoFromGroup?.superobjectTypeId) {
      superObjectTypeIds.push(relationInfoFromGroup.superobjectTypeId);
    }
    else {
      superObjectTypeIds = this.extendedSearchService.extendedSearchParameters().superobject_type_ids;
    }

    let folderId = this.searchContainer().getTargetId();

    if (folderId === 'none') {
      folderId = '';
    }

    this.extendedSearchService.getExtendedSearchFieldValues(this.selfField(), query, superObjectTypeIds, 150, folderId).then((values) => {
      this.values.set(values);
    })
  }
}
