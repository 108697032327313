import {Injectable} from '@angular/core';
import {SearchResult} from '../core/definitions/search-result';
import {SearchParameterService} from './search-parameter.service';
import {SearchParameters} from '../core/definitions/search-parameters';
import {PerformanceTimer} from '../core/performance-timer';
import {SearchResultSelectionsService} from './search-result-selections.service';
import {AppNotification, NotificationService} from '../shared/notification.service';
import {SearchStateService} from './search-state.service';
import {SearchFacetService} from './search-facet.service';
import {ResultViewService} from './result-view.service';
import {ListRange} from '@angular/cdk/collections';
import {SearchObject, SearchObjectRow} from '../core/definitions/search-object';
import {SearchResultViewType} from '../core/definitions/search-result-view-type.enum';
import {SearchContainer} from '../core/definitions/search-container';
import {SearchService} from "../core/search.service";
import {DbSearchCount} from "../core/definitions/db-search-count";
import {LoggerService} from "../core/logger.service";

@Injectable({
  providedIn: 'root'
})
export class SearchExecutorService {

  constructor(private searchParameterService: SearchParameterService,
              private searchService: SearchService,
              private searchResultSelectionsService: SearchResultSelectionsService,
              private searchFacetService: SearchFacetService,
              private notificationService: NotificationService,
              private searchStateService: SearchStateService,
              private resultViewService: ResultViewService,
              private logger: LoggerService) {
  }

  private searchCountRetryCount = 0;
  private fakeCountUpId: any;
  // If server side constant LIMIT_COUNT is changed, this variable must be updated
  private limitCount = 100;

  async runSearch(searchContainer: SearchContainer, continueScroll?: boolean): Promise<SearchResult> {
    const result = await this.startSearch(searchContainer, continueScroll);
    if (result) {
      this.executeSearchResultCallbacks(searchContainer);
    }
    return result;
  }

  runScrollSearch(searchContainer: SearchContainer, deltaY: number) {
    let doSearch = false;
    const scrollElement = searchContainer.scrollInfo.scrollElement;
    searchContainer.scrollToY = scrollElement.scrollTop;
    if (!searchContainer.scrollInfo.searchingScroll) {
      const scrollPos = scrollElement.offsetHeight + scrollElement.scrollTop;
      if (deltaY > 0 && scrollPos > scrollElement.scrollHeight - 1300) {
        doSearch = this.setSearchRowsCheckMustRunScrollSearch(searchContainer);
      } else {
        searchContainer.animateNewSearchResult = false;
      }
    }
    if (doSearch) {
      searchContainer.scrollInfo.searchingScrollDir = 'down';
      searchContainer.animateNewSearchResult = true;
      this.startScrollSearch(searchContainer).then(objects => {
        if (searchContainer.newScrollItemsCallback) {
          searchContainer.newScrollItemsCallback(objects);
        }
      });
    }
  }

  async runVirtualScrollSearch(searchContainer: SearchContainer, range: ListRange, useColumns: boolean): Promise<boolean> {
    const pageInfo = this.getPageInfo(searchContainer, range);
    let hasSearchRes = false;
    const pages: number[] = [];
    for (let page = pageInfo.startPage; page <= pageInfo.endPage; page++) {
      if (this.checkAddPage(searchContainer, page)) {
        pages.push(page);
      }
    }
    if (!pages.length) {
      return hasSearchRes;
    }
    const searchResult = await this.startVirtualScrollSearch(searchContainer, pageInfo, pages);
    if (searchResult) {
      this.searchResultSelectionsService.setSelectedUsed(searchContainer, searchResult);
      hasSearchRes = true;
      this.addSearchRowsToCache(searchContainer, searchResult, pages, pageInfo.pageSize, useColumns);
    }
    return hasSearchRes;
  }

  resetSearchPosition(searchContainer: SearchContainer) {
    searchContainer.searchPage = 1;
    searchContainer.searchRow = undefined;
    searchContainer.virtualScrollViewOffset = null;
  }

  subscribeToSearchResult(searchContainer: SearchContainer, callback: any) {
    searchContainer.searchResultCallbacks.push(callback);
    if (searchContainer.searchResult) {
      // Need to run callback immediately if search result exists in order to prevent situations where search result
      // is missing from caller
      this.runCallback(searchContainer, callback);
    }
  }

  unSubscribeToSearchResult(searchContainer: SearchContainer, callback: any) {
    let callbackItem: any;
    const callbacks = searchContainer.searchResultCallbacks;
    for (let t = 0; t < callbacks.length; t++) {
      callbackItem = callbacks[t];
      if (callbackItem === callback) {
        callbacks.splice(t, 1);
        break;
      }
    }
  }

  private setSearchRowsCheckMustRunScrollSearch(searchContainer: SearchContainer) {
    const defaultRows = this.resultViewService.getDefaultCalculatedRowsColumns(searchContainer.searchResultViewName);
    let res = false;
    if (searchContainer.searchRow === undefined) {
      searchContainer.searchRow = defaultRows.rows / defaultRows.columns;
      res = true;
    } else {
      const start = searchContainer.searchRow * defaultRows.columns;
      if (start < searchContainer.searchResult.search_count) {
        const tileData = this.resultViewService.getViewTileData()[searchContainer.searchResultViewName];
        const addRows = tileData.scrollAddRows;
        searchContainer.searchRow += addRows;
        res = true;
      }
    }
    return res;
  }

  private checkAddPage(searchContainer: SearchContainer, page: number) {
    if (searchContainer.fetchedPages.has(page)) {
      return false;
    }
    searchContainer.fetchedPages.add(page);
    return true;
  }

  private getPageInfo(searchContainer: SearchContainer, range: ListRange) {
    const defaultRows = this.resultViewService.getDefaultCalculatedRowsColumns(searchContainer.searchResultViewName);
    const pageSize = defaultRows.tileRows;
    const startPage = this.getPageForIndex(range.start, pageSize);
    const endPage = this.getPageForIndex(range.end - 1, pageSize);
    return {
      pageSize: pageSize,
      startPage: startPage,
      endPage: endPage,
      columns: defaultRows.columns
    };
  }

  private getPageForIndex(index: number, pageSize: number): number {
    return Math.floor(index / pageSize);
  }

  private async startSearch(searchContainer: SearchContainer, continueScroll?: boolean): Promise<SearchResult> {
    const viewName = searchContainer.searchView.view_name;
    const timer = new PerformanceTimer('runSearch ' + viewName);
    searchContainer.fetchedPages = new Set<number>();
    searchContainer.cachedScrollDataColumns = [];
    searchContainer.cachedScrollDataList = [];

    timer.start();
    searchContainer.searching = true;
    let searchRes: SearchResult;
    const searchParams = await this.searchParameterService.getSearchParams(searchContainer, continueScroll);
    searchParams.$$searchId = Math.random();
    const isDbSearch = searchParams.search_engine === 'advanced' && searchParams.advanced_search_params?.db_search;
    try {
      if (isDbSearch) {
        searchRes = await this.searchService.searchDbWithOverview(searchParams, searchContainer);
        searchContainer.dbSearchCorrelationId = searchRes.correlation_id;
        if (searchRes.search_count === this.limitCount) {
          this.fakeCountUp(searchContainer);
          this.searchService.getDbSearchCount(searchParams, searchContainer).then(countRes => {
            this.setSearchCount(searchContainer, countRes, searchParams.$$searchId);
          });
        }
      } else {
        searchRes = await this.searchService.searchWithOverview(searchParams);
      }
    } catch (e) {
      console.error(`Search failed: ${e.error?.message || e.message}`);
      return;
    }
    if (!searchRes) {
      console.warn('Search result is empty');
      return;
    }
    searchRes.$$searchResId = searchParams.$$searchId;
    timer.stop('Search success');
    if (searchRes.status !== 'failed') {
      searchContainer.searchResult = searchRes;
      this.searchResultSelectionsService.setSelectedUsed(searchContainer, searchRes);
      await this.resultViewService.setSearchResultItemProps(searchContainer);
      this.searchFacetService.setFacetCount(searchContainer);
      searchContainer.filtersFacets.facetRangeGroups = searchRes.facet_range_groups;
      this.setMaxPage(searchContainer);
      if (searchContainer.searchPostFn) {
        searchContainer.searchPostFn(searchRes);
      }
      await this.searchStateService.setState(searchContainer);
      if (searchContainer.virtualScrollEnabled) {
        const columns = this.resultViewService.getDefaultCalculatedRowsColumns(searchContainer.searchResultViewName).columns;
        if (searchContainer.searchResultViewName === SearchResultViewType.THUMBNAIL ||
          searchContainer.searchResultViewName === SearchResultViewType.GALLERY) {
          const cachedLength = Math.ceil(searchRes.search_count / columns);
          searchContainer.cachedScrollDataColumns = Array.from<SearchObjectRow>({length: cachedLength});
        } else {
          searchContainer.cachedScrollDataList = Array.from<SearchObject>({length: searchRes.search_count});
        }
      }

      if (searchContainer.dbSearchCorrelationId) {
        searchContainer.dbSearchCorrelationId = null;
      }

      searchContainer.searching = false;
      return searchRes;
    } else {
      this.addSearchResError(searchRes, searchParams);
      if (searchContainer.dbSearchCorrelationId) {
        searchContainer.dbSearchCorrelationId = null;
      }
      searchContainer.searching = false;
      return searchRes;
    }
  }

  private setSearchCount(searchContainer: SearchContainer, countRes: DbSearchCount, searchId: number) {
    if (searchContainer.searchResult?.$$searchResId === searchId) {
      if (this.fakeCountUpId) {
        clearTimeout(this.fakeCountUpId);
        this.fakeCountUpId = undefined;
      }
      searchContainer.searchResult.search_count = countRes.count;
    } else {
      if (this.searchCountRetryCount === 0) {
      }
      if (this.searchCountRetryCount < 20) {
        this.searchCountRetryCount++;
        setTimeout(() => this.setSearchCount(searchContainer, countRes, searchId), 500);
      } else {
        this.logger.warn('Search count retry limit reached');
        this.searchCountRetryCount = 0;
      }
    }
  }

  private fakeCountUp(searchContainer: SearchContainer) {
    if (searchContainer.searchResult?.search_count) {
      searchContainer.searchResult.search_count += 1 + Math.floor(Math.random() * 5);
    }
    this.fakeCountUpId = setTimeout(() => {
      this.fakeCountUp(searchContainer);
    }, 200)
  }

  private async startScrollSearch(searchContainer: SearchContainer): Promise<SearchObject[]> {
    searchContainer.scrollInfo.searchingScroll = true;
    const searchParams = await this.searchParameterService.getSearchParams(searchContainer, true, true);
    const searchRes = await this.searchService.searchWithOverview(searchParams);
    await this.resultViewService.setSearchResultItemProps(searchContainer, searchRes.artifacts);
    if (searchRes.artifacts && searchRes.artifacts.length) {
      searchContainer.searchResult.artifacts = searchContainer.searchResult.artifacts.concat(searchRes.artifacts);
    }
    this.searchResultSelectionsService.setSelectedUsed(searchContainer, searchContainer.searchResult);
    searchContainer.scrollInfo.searchingScroll = false;
    return searchRes.artifacts;
  }

  private async startVirtualScrollSearch(searchContainer: SearchContainer, pageInfo: any, pages: number[]): Promise<SearchResult> {
    const searchParams = await this.searchParameterService.getSearchParams(searchContainer, true, true);
    searchParams.rows = pageInfo.pageSize * pageInfo.columns * pages.length;
    searchParams.start = pages[0] * searchParams.rows;
    const searchRes = await this.searchService.searchWithOverview(searchParams);
    await this.resultViewService.setSearchResultItemProps(searchContainer, searchRes.artifacts, searchParams.start);
    return searchRes;
  }

  private executeSearchResultCallbacks(searchContainer: SearchContainer) {
    searchContainer.searchResultCallbacks.forEach((callback) => {
      this.runCallback(searchContainer, callback);
    });
  }

  private runCallback(searchContainer: SearchContainer, callback: any) {
    // A timeout is necessary in order to set some useful properties before running callbacks
    // (setting checked menus etc.)
    if (callback.$$timeOut) {
      clearTimeout(callback.$$timeOut);
    }
    callback.$$timeOut = setTimeout(() => {
      // Preventing callback from being run twice on same search result
      if (callback.$$lastSearchResId !== searchContainer.searchResult.$$searchResId) {
        callback(searchContainer.searchResult);
        callback.$$lastSearchResId = searchContainer.searchResult.$$searchResId;
      }
      callback.$$timeOut = null;
    }, 500);
  }

  private addSearchResError(searchRes: SearchResult, params: SearchParameters) {
    if (searchRes.status_msg === 'cancelled') {
      this.logger.debug('Search cancelled');
      return;
    }
    console.warn('Search error: ' + searchRes.status_msg + ': ' + JSON.stringify(params));
    this.notificationService.addNotification(new AppNotification(
      ['TRANS__SEARCH__ILLEGAL_SEARCH', ': \'', searchRes.status_msg, '\''],
      'error',
      null,
      searchRes.status_code));
  }

  private setMaxPage(searchContainer: SearchContainer) {
    const sc = searchContainer;
    sc.maxPage = Math.ceil(searchContainer.searchResult.search_count / sc.rows[sc.searchResultViewName]);
  }

  private addSearchRowsToCache(searchContainer: SearchContainer, searchResult: SearchResult, pages: number[],
                               pageSize: number, useColumns: boolean) {
    const splicePageSize = pageSize * pages.length;
    const spliceStart = pages[0] * splicePageSize;
    if (useColumns) {
      const columns = this.resultViewService.getDefaultCalculatedRowsColumns(searchContainer.searchResultViewName).columns;
      let artifactIndex = 0;
      const searchRows: SearchObjectRow[] = [];
      do {
        const newRow = new SearchObjectRow();
        searchRows.push(newRow);
        for (let t = 0; t < columns; t++) {
          if (artifactIndex < searchResult.artifacts.length) {
            newRow.columns.push(searchResult.artifacts[artifactIndex++]);
          }
        }
      } while (artifactIndex < searchResult.artifacts.length);
      searchContainer.cachedScrollDataColumns.splice(spliceStart, splicePageSize, ...searchRows);
    } else {
      searchContainer.cachedScrollDataList.splice(spliceStart, splicePageSize, ...searchResult.artifacts);
    }
  }

}
