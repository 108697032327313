import { Component, input, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { ExtendedSearchService } from '../../core/extended-search.service';
import { SearchContainer } from '../../core/definitions/search-container';
import {
  ExtendedSearchQueryBuilderGroupComponent
} from './extended-search-query-builder-group/extended-search-query-builder-group.component';
import { SearchExecutorService } from '../search-executor.service';
import { MatChip } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { InformationModalComponent } from '../../shared/information-modal/information-modal.component';
import { SearchService } from '../../core/search.service';

@Component({
  selector: 'app-extended-search-query-builder',
  standalone: true,
  imports: [
    MatButton,
    TranslateModule,
    MatIcon,
    ExtendedSearchQueryBuilderGroupComponent,
    MatChip
  ],
  templateUrl: './extended-search-query-builder.component.html',
  styleUrl: './extended-search-query-builder.component.scss'
})
export class ExtendedSearchQueryBuilderComponent implements OnInit{
  searchContainer = input.required<SearchContainer>();
  isSearching = false;
  private searchPromise: Promise<any> | null = null;

  constructor (
    public readonly extendedSearchService: ExtendedSearchService,

    private readonly modal: MatDialog,
    private readonly searchExecutor: SearchExecutorService,
    private readonly searchService: SearchService
  ) {}

  ngOnInit () {
    if (this.searchContainer().queryContainer.query !== '') {
      this.searchContainer().queryContainer.query = '';
    }

    this.extendedSearchService.initializeOrCreateSearchParametersFromSearchContainer(this.searchContainer());
  }

  get rulesHasValues(): boolean {
    return this.extendedSearchService.getRulesHasValues();
  }

  addNewGroupToRoot() {
    this.extendedSearchService.addNewGroupToRoot();
  }

  resetExtendedSearch() {
    this.extendedSearchService.resetExtendedSearch();

    this.extendedSearchService.triggerFieldSelectorUpdate();
  }

  triggerExtendedSearch() {
    if (!this.rulesHasValues) {
      this.modal.open(InformationModalComponent, {
        data: {
          title: 'TRANS__ADVANCED_SEARCH__NO_RULES_DEFINED_TITLE',
          content: 'TRANS__ADVANCED_SEARCH__NO_RULES_DEFINED_CONTENT',
          buttonText: 'TRANS__ADVANCED_SEARCH__NO_RULES_DEFINED_OK'
        }
      });
    }
    else {
      this.isSearching = true;
      this.searchPromise = this.searchExecutor.runSearch(this.searchContainer())
        .then((result) => {
          this.isSearching = false;
          this.searchPromise = null;
          return result;
        })
        .catch((error) => {
          this.isSearching = false;
          this.searchPromise = null;
          throw error;
        });
    }
  }

  cancelSearch() {
    if (this.isSearching) {
      this.searchService.cancelDbCachePolling(this.searchContainer().dbSearchCorrelationId).then();
    }
  }
}
