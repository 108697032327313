<app-hibernate-wakeup-overlay></app-hibernate-wakeup-overlay>
@if (showApiSelector()) {
  <!-- GUI for choosing the Primus backend to connect to -->
  <app-choose-backend-api class="authentication-container__choose-backend">
  </app-choose-backend-api>
} @else if (currentLoadingSteps()) {
  <app-splash-screen [currentSteps]="currentLoadingSteps()"></app-splash-screen>
} @else if (!isAuthenticated()) {
  <app-authenticator></app-authenticator>
} @else {
  <!-- Main application -->
    <!-- Old layout with old styling code -->
  @if (!mainmenuV2) {
    @if (isReportPreview()) {
      <router-outlet></router-outlet>
    } @else {
      <div class="app-component"
           [ngClass]="className" (click)="registerClick($event)">
        <div class="app-component__main-menu"
             *ngIf="hasMainMenu()">
          <div class="app-component__main-menu-container legacy-icons">
            <app-main-menu
              (toggleFaqSidebar)="showFaqSidebar($event)"
              [currentStateName]="currentStateName"
              class="app-component__main-menu-content"></app-main-menu>
          </div>
        </div>
        <div class="app-component__container">
          <mat-drawer-container class="app-root__drawer-container" [hasBackdrop]="false">
            <mat-drawer
              class="faq-sidebar"
              mode="over"
              position="end"
              [opened]="showFaq"
            >
              <app-faq-sidebar
                (closeFaqSidebar)="showFaqSidebar(false)"
              ></app-faq-sidebar>
            </mat-drawer>

            <mat-drawer-content class="drawer-content">
              <div class="app-component__content">
                <router-outlet></router-outlet>
              </div>
            </mat-drawer-content>
          </mat-drawer-container>

          <app-notifications></app-notifications>
        </div>
      </div>
    }
  }

  <!-- New layout with flex -->
  @if (mainmenuV2) {
    <div class="app" id="app-component">
      <app-main-menu-v2
        (toggleFaqSidebar)="showFaqSidebar($event)"
        [currentStateName]="currentStateName"
      />

      <mat-drawer-container [hasBackdrop]="false" class="app__drawer-container">
        <mat-drawer
          mode="over"
          position="end"
          [opened]="showFaq"
        >
          <app-faq-sidebar
            (closeFaqSidebar)="showFaqSidebar(false)"
          />
        </mat-drawer>

        <mat-drawer-content class="app__drawer-content">
          <router-outlet></router-outlet>
        </mat-drawer-content>
      </mat-drawer-container>

      <app-main-menu-v2-footer
        class="app__footer"
        [currentStateName]="currentStateName"
      />
      <app-notifications></app-notifications>
    </div>
  }
}
