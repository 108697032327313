import { Component, input } from '@angular/core';
import {
  ExtendedFieldQueryGroup,
  ExtendedFieldQueryLogicalOperator
} from '../../../core/definitions/extended-search-params';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import {
  ExtendedSearchQueryBuilderFieldComponent
} from '../extended-search-query-builder-field/extended-search-query-builder-field.component';
import { SearchContainer } from '../../../core/definitions/search-container';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { ExtendedSearchService } from '../../../core/extended-search.service';
import { SearchExecutorService } from '../../search-executor.service';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-extended-search-query-builder-group',
  standalone: true,
  imports: [
    TranslateModule,
    MatButton,
    MatIcon,
    MatIconButton,
    MatTooltip,
    ExtendedSearchQueryBuilderFieldComponent,
    MatMenu,
    MatMenuTrigger,
    MatMenuItem,
    MatTooltip,
    NgClass
  ],
  templateUrl: './extended-search-query-builder-group.component.html',
  styleUrl: './extended-search-query-builder-group.component.scss'
})
export class ExtendedSearchQueryBuilderGroupComponent {
  rootGroup = input.required<ExtendedFieldQueryGroup>();
  searchContainer = input.required<SearchContainer>();
  selfGroup = input.required<ExtendedFieldQueryGroup>();
  parentGroup = input<ExtendedFieldQueryGroup | null>(null);

  constructor(
    private readonly extendedSearchService: ExtendedSearchService,
    private readonly searchExecutorService: SearchExecutorService
  ) {}

  addNewField(): void {
    this.extendedSearchService.addNewFieldToGroup(this.selfGroup());
  }

  addNewGroup(): void {
    this.extendedSearchService.addNewSubGroupToGroup(this.selfGroup());
  }

  getGroupLogicalOperatorDisplay(): string {
    if (this.selfGroup().field_logical_operator === 'OR') {
      return 'TRANS__OPERATOR__OR';
    }
    else if (this.selfGroup().field_logical_operator === 'AND') {
      return 'TRANS__OPERATOR__AND';
    }
    else if (this.selfGroup().field_logical_operator === 'NOT') {
      return 'TRANS__OPERATOR__NOT';
    }
  }

  getSubgroupTitleText(): string {
    // Root group (level 0)
    if (this.selfGroup().level === 0) {
      return 'TRANS__ADVANCED_SEARCH__SHOW_RESULTS_THAT_MEET';
    }
    
    // Subgroup with no parent or parent is root
    if (!this.parentGroup() || this.parentGroup().level === 0) {
      return 'TRANS__ADVANCED_SEARCH__SUBGROUP_THAT_MEETS';
    }
    
    // Nested subgroup with parent that has OR operator
    if (this.parentGroup().field_logical_operator === 'OR') {
      return 'TRANS__ADVANCED_SEARCH__SUBGROUP_SOME_OF';
    }
    
    // Default for other nested subgroups
    return 'TRANS__ADVANCED_SEARCH__SUBGROUP_THAT_MEETS';
  }

  removeSelf(): void {
    this.extendedSearchService.removeGroup(this.selfGroup());

    this.searchExecutorService.runSearch(this.searchContainer()).then();
  }

  updateGroupLogicalOperator(operator: ExtendedFieldQueryLogicalOperator) {
    this.extendedSearchService.setGroupOperator(this.selfGroup(), operator);
  }
}
