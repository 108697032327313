@if(objectFields) {
  <div class="list__container">
    <table mat-table [dataSource]="dataSource" [fixedLayout]="true">
      <ng-container matColumnDef="artifact_name" [sticky]="true">
        <th
          mat-header-cell
          *matHeaderCellDef
          (keyDown)="null"
          (click)="sort(artifactNameField)"
        >
          <div class="list__header"> 
            <mat-checkbox
              (ngModelChange)="selectAll()"
              [(ngModel)]="searchContainer.selections.allSelected"
              [indeterminate]="checkedStatus() === 'some'"
            />
            <span>
              {{'TRANS__FIELD__NAME' | translate}}
            </span>
            @if (searchContainer.getOrder() === 'artifact_name' || searchContainer.getOrder() === ('artifact_name desc')) {
              <mat-icon 
              class="list__sort-icon"
                [ngClass]="{'list__sort-icon--flipped': searchContainer.getOrder() === ('artifact_name desc')}"
              >
                arrow_downward
              </mat-icon>
          }
          </div>
        </th>
        <td
          id="{{row.item.artifact_id}}"
          mat-cell
          *matCellDef="let row"
        >
          <div class="list__name-column">
            <span class="checkbox-tooltip-wrapper"
                  [class.cursor-not-allowed]="isCheckboxDisabled(row) && !row.$$used"
                  [matTooltip]="isCheckboxDisabled(row) && !row.$$used ? ('TRANS__SEARCH_SELECTIONS__TOO_MANY_SELECTED' | translate) : ''"
                  (click)="$event.stopPropagation()">
              <mat-checkbox
                (click)="select(row, $event)"
                [checked]="isCheckboxSelected(row)"
                [disabled]="isCheckboxDisabled(row)"
              />
            </span>

            <button
              mat-icon-button
              (click)="treeControl.toggle(row)"
              [style.visibility]="!isExpandable(row) ? 'hidden' : 'visible'"
            >
              @if(!row['isLoading']) {
                <mat-icon>
                  {{treeControl.isExpanded(row) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              }
              @else {
                <mat-spinner
                  diameter="16"
                />
              }
            </button>

            <div>
              <a
                queryParamsHandling="merge"
                [queryParams]="row['refData']['queryParams']"
                [routerLink]="getRouterLink(row)"
                class="name-column__link"
              >
                <mat-icon>
                  {{ iconToMaterialSymbol(row.icon) }}
                </mat-icon>
                <span [innerHTML]="row.name"></span>
              </a>
            </div>
          </div>
        </td>
      </ng-container>

      @for (column of searchContainer.currentPathView.search_view.overview_fields; track $index) {
        <ng-container [matColumnDef]="column.$$fieldColumnName">
          <th
            mat-header-cell
            *matHeaderCellDef
            (keyDown)="null"
            (click)="sort(column)"
          >
            <div class="list__header">
              <span>
                {{ column.$$fieldTitle }}
              </span>
    
              @if (searchContainer.getOrder() === column.field_name + "_value" ||
               searchContainer.getOrder() === (column.field_name + '_value desc')) {
                <mat-icon 
                class="list__sort-icon"
                  [ngClass]="{'list__sort-icon--flipped': searchContainer.getOrder() === (column.field_name + '_value desc')}"
                >
                  arrow_downward
                </mat-icon>
              }
            </div>

          </th>

          <td
            mat-cell
            *matCellDef="let row"
          >
            @for(field of getObjectFields(row); track $index) {
              <div [title]="field.title">
                @if(column.$$fieldColumnName === field.$$fieldColumnName) {
                  <app-overview-field-value
                    [object]="row.item"
                    [viewName]="searchContainer.searchResultViewName"
                    [overviewField]="field"
                    [metaField]="column"
                  />
                }
              </div>
            }
          </td>
        </ng-container>
      }

      <ng-container matColumnDef="setting" [stickyEnd]="true">
        <th
          mat-header-cell
          *matHeaderCellDef
        >
          <app-operation-dropdown-menu-for-search-container
            [searchContainer]="searchContainer"
            [operationGroupId]="'list_search_view_dropdown'"
          />
        </th>
        <td mat-cell *matCellDef="let element"></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.your-class]="row.your-condition"></tr>
    </table>
  </div>
}
